.error-not-found {
  min-height: 100vw;
  padding-top: 48px;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 140px;

    img {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .error-not-found {
    min-height: 100vw;
    padding-top: 48px;

    a {
      text-align: center;

      img {
        width: 70%;
      }
    }

    .logo-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
    }
  }
}