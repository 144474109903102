html {
  scroll-behavior: smooth !important;
}
body {
  scroll-behavior: smooth !important;
  padding: 0px !important;
  overflow: auto !important;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1390px;
  width: 100%;
  display: table;
  padding: 0 15px;
}
.text-orange {
  color: #eb7c30;
}

.text-blue {
  color: #d9e1f1;
}

.text-coffee {
  color: #bd8f00;
}

.logo-Sign-up .image-brand {
  height: 43px;
  width: 120px;
  margin: 40px 0px 0px 100px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .logo-Sign-up .image-brand {
    display: none;
  }
  .header-user {
    display: none;
  }
}

@media screen and (min-width: 991px) {
  .mobile-view-logout {
    display: none;
  }
}
.mobile-view-logout {
  position: absolute;
  right: 20px;
  top: 6px;
  z-index: 5;
  cursor: pointer;
}
.nav-link-mobile::after {
  display: none !important;
  cursor: pointer !important;
}
.log-out-user {
  height: 30px;
  width: 30px;
}
.log-out-arrow {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

/* loader css  */
.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh !important;
  width: 100%;
}

.dot1 {
  background: #44546a;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: inline-block;
  animation: dots 1s ease-in infinite;
  margin-right: 5px;
}

.dot2 {
  background: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: dots 1s 0.25s ease-in infinite;
  display: inline-block;
  margin-right: 5px;
}

.dot3 {
  background: #cfcb00;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  display: inline-block;
  animation: dots 1s 0.5s ease-in infinite;
}

@keyframes dots {
  0% {
    transform: translateY(100px);
    opacity: 1;
  }
  50% {
    transform: translateY(80px);
    opacity: 0.7;
  }
  60% {
    transfom: translateY(80px);
  }
  100% {
    transform: translateY(100px);
    opacity: 1;
  }
}
.you-win-title {
  color: #00b050;
}
.you-loss-title {
  color: #ff0000;
}
.draw-title {
  color: #4472c4;
}
.wrong-ans {
  color: #ffffff !important;
  background: #ec4648 !important;
}

.right-ans {
  color: #ffffff !important;
  background: #5cd149 !important;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}


*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d0cece;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  /* background-color: black !important; */
}
.close-icon-wrapper {
  cursor: pointer;
}
.close-icon-wrapper img {
  padding: 6px;
}
.mobile_screen_back_icon {
  padding: 6px;
}



.text-line-nowrap{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}