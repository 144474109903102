.notification_wrapper {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translate(-50%, 0%);
  padding: 14px;
  z-index: 999999;
  max-width: 500px;
  width: 100%;
  min-height: 100px;
  box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  background: #000000;
  .topic_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .topic_image {
      height: 38px;
      width: 38px;
      border-radius: 50%;
    }
    .topic_name {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #7fba1f;
      margin-bottom: 0;
    }
    .lt-wrapper-loss-won {
      display: flex;
      align-items: center;

      .loss_learning_token {
        background: #ff6600;
        border-radius: 11.5px 0 0 11.5px;
        padding: 7px 16px 8px 11px;
        height: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;
        color: #ffffff;
        margin-right: -8px;
      }

      img {
        z-index: 2;
        position: relative;
      }

      .won_learning_token {
        background: #ff6600;
        border-radius: 0 11.5px 11.5px 0;
        padding: 7px 11px 8px 16px;
        height: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;
        color: #ffffff;
        margin-left: -8px;
      }
      .won-color {
        background: rgba(127, 186, 31, 0.25) !important;
      }
      .lost-color {
        background: rgba(255, 0, 0, 0.25) !important;
      }
      .draw-color {
        background: rgba(5, 117, 192, 0.25) !important;
      }
    }
  }
  .challenge_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .challenge_title {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
      color: #aca800;
      margin-bottom: 0;
      white-space: nowrap;
      width: fit-content;
      margin-right: 10px;
    }
    .button_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .accept_button {
        background: #5cd149;
        border-radius: 4px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        padding: 8px 14px;
      }
      .play_later_button {
        background: #ffc000;
        border-radius: 4px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        padding: 8px 14px;
      }
      .decline_button {
        background: #ec4648;
        border-radius: 4px;
        border: none;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        padding: 8px 14px;
      }
    }
  }
  .opponent_name_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .opponent_name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-bottom: 0;
    }
    .opponent_time {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #ffffff;
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 568px) {
  .notification_wrapper {
    top: 60px;

    .challenge_wrapper {
      .challenge_title {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      .button_wrapper {
        .accept_button {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 8px 10px;
        }
        .play_later_button {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 8px 10px;
        }
        .decline_button {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 8px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .notification_wrapper {
    .challenge_wrapper {
      .challenge_title {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
      }
      .button_wrapper {
        .accept_button {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          padding: 4px 8px;
        }
        .play_later_button {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          padding: 4px 8px;
        }
        .decline_button {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          padding: 4px 8px;
        }
      }
    }
  }
}
.activity-delete-modal {
  max-width: 556px;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 20px;

    .activity-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
      }

      .activity-contend {
        .activity-image {
          margin-top: 8px;
        }

        h2 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: #322e2f;
          margin: 25px 0px 30px 0px;
        }

        .activity-details {
          margin: auto;

          p {
            // max-width: 337px;
            margin: auto;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #a5a5a5;

            span {
              // color: rgba(232, 98, 108, 1);
            }
            b {
              color: #322e2f;
            }
          }
        }

        .activity-button-wrapper {
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 70px;
          margin-bottom: 26px;

          .cancel-btn {
            background: #44546a;
            border-radius: 10px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
            margin-right: 30px;
            border: none;
            padding: 13px 0px;
            width: 215px;
          }

          .delete-btn {
            background: #eb5569;
            border-radius: 10px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            border: none;
            padding: 13px 0px;
            color: #ffffff;
            width: 215px;
          }

          .withdraw-btn {
            background: #ea9601;
            border-radius: 10px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            border: none;
            padding: 13px 0px;
            color: #ffffff;
            width: 215px;
          }
        }
      }
    }
  }
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
}
