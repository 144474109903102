.online-friends-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 80px 0px;

  .online-card-wrapper {
    background: #ffffff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    // min-width: 660px;
    width: 660px;
    max-width: 660px;
    padding: 24px 50px 21px 50px;
    // min-height: 83vh;

    .online-card-title-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dedede;
      margin-bottom: 15px;

      img {
        height: 13px;
        width: 13px;
        cursor: pointer;
        // display: none;
      }

      h3 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #322e2f;
        width: 100%;
        margin: 0;
        cursor: default;
      }
    }

    .input-wrapper {
      margin-bottom: 20px;

      input {
        background: rgba(227, 227, 227, 0.5);
        border-radius: 23.5px;
        border: none;
        outline: none;
        padding: 10px 47px;
        width: 100%;
        background-image: url("../../Assets/Img/search-input.svg");
        background-repeat: no-repeat;
        background-position: 25px 50%;
        font-family: "Rubik" !important;
      }
    }

    .online-user {
      h4 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #a5a5a5;
        margin-bottom: 15px;
      }

      .challenge-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #dedede;

        .userName-wrapper {
          display: flex;
          align-items: center;

          .avatar {
            width: 45px;
            height: 45px;
            margin-right: 15px;
          }

          p {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #322e2f;
            margin: 0 20px 0 0;
          }

          .country-logo {
            width: 27px;
            height: 18px;
          }
        }

        .challenge-btn {
          border: 0.8px solid #5cd149;
          border-radius: 16px;
          padding: 8px 20px;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #5cd149;
          background-color: transparent;
        }

        .challenge-btn:hover {
          background: rgba(92, 209, 73, 0.2);
        }
      }

      .record-not-found {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 10px;
        }

        p {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #a5a5a5;
          margin: 0;
        }
      }
    }
  }
}

.challenge-modal {
  max-width: 607px !important;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px 22px 40px 22px;

    .challenge-modal-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        img {
          cursor: pointer;
        }
      }
    }
    .mb-40 {
      margin-bottom: 40px;
    }

    .blockchain-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: 40px;

      img {
        width: 30px;
        height: 30px;
        margin-right: 30px;
      }

      h1 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #5e00f6;
        margin-bottom: 0;
        cursor: default;
      }
    }

    .challenge-img-wrapper {
      display: flex;
      align-items: center;
      margin: 0px 55px 16px 55px;

      .user-first-img {
        width: 20%;
        height: 20%;
        max-width: 20%;
        border-radius: 50%;
        border: 4px solid #ffcbcc;
      }

      .logo {
        width: 60%;
      }

      .center-line {
        height: 3px;
        border: 3px solid #cfcb00;
        width: 50%;
      }

      .center-logo {
        max-width: 91px;
        max-height: 35px;
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 55px 67px 55px;

      .user {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #322e2f;
        margin: 0px 20px 0px 0px;
        cursor: default;
      }

      img {
        object-fit: contain;
        width: 27px;
        height: 18px;
      }
    }

    .reward-wrapper {
      width: fit-content;
      height: 36px;
      background: #ffe0cc;
      border-radius: 50px 5px 5px 50px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: default;

      img {
        width: 36px;
        height: 36px;
      }

      p {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #ff6600;
        margin: 0px;
        padding: 0 20px 0 15px;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      border: none;
      padding: 0px;
      margin: 0px;
      margin-bottom: 50px;

      button {
        padding: 12px 25px;
        border: none;
        background: #1a2b51;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;
        align-items: center;

        span {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
          margin: 0;
        }

        img {
          height: 16px;
          width: 16px;
          margin: 0 26px;
        }
      }
    }
  }
}

.waiting-user-modal {
  max-width: 607px;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px;
    background-image: url("../../Assets/Img/waitingChallenge.svg");
    background-repeat: no-repeat;
    background-position: center bottom;

    .waiting-user-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      .waiting-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #322e2f;
        margin-bottom: 56px;
        text-align: center;
        padding: 0px 100px;
      }

      .starting-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #a5a5a5;
        margin-bottom: 13px;
      }

      .count-down-timer-wrapper {
        margin-bottom: 64px;

        .time-count {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          text-align: center;
          color: #1a2b51;
          margin-bottom: 0;
        }

        .time-count-second {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 60px;
          line-height: 71px;
          text-align: center;
          color: #1a2b51;
          margin-bottom: 0;
        }
      }

      .cancel-btn {
        background: #1a2b51;
        border-radius: 10px;
        border: none;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 14px 30px;
      }
      .withdraw-btn {
        background: #ea9601;
        border-radius: 10px;
        border: none;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 14px 30px;
      }
    }
  }
}

.looking-opponent-modal {
  max-width: 607px;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px;

    .waiting-user-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      h1 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #322e2f;
        margin-bottom: 0px;
      }

      .user-data {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-bottom: 18px;

        img {
          width: 128.2px;
          height: 128.2px;
        }
      }

      .random-user {
        position: relative;
        z-index: 999;
      }

      .earth-routed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 170px;
        width: 170px;
        background: #5d73a4;
        margin: auto;
        background-image: url(../../Assets/Img/earth.png);
        background-size: cover;
        border-radius: 50%;
        background-position: 680px center;
        animation: round 15s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        box-shadow: inset -6px 2px 20px 0px #00000094, 5px 0 28px -4px #0000004a inset;
        -moz-box-shadow: inset -6px 2px 20px 0px #00000094, 5px 0 28px -4px #0000004a inset;
        -webkit-box-shadow: inset -6px 2px 20px 0px #00000094, 5px 0 28px -4px #0000004a inset;
      }

      @keyframes round {
        0% {
          background-position: 0px center;
        }

        100% {
          background-position: 700px center;
        }
      }

      .cancel-btn {
        background: #1a2b51;
        border-radius: 10px;
        border: none;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding: 14px 30px;
        margin: 26px 0px 13px 0px;
      }
    }
  }
}

.pending-opponent-modal {
  max-width: 607px;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px;

    .pending-user-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        h1 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: #322e2f;
          max-width: 340px;
        }
      }

      p {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #a5a5a5;
        margin-bottom: 75px;

        span {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #322e2f;
          margin: 0;
        }
      }

      .footer-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cancel-btn {
          background: #cfcb00;
          border-radius: 10px;
          border: none;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          padding: 14px 0;
          width: 47%;
        }

        .activity-btn {
          background: #44546a;
          border-radius: 10px;
          border: none;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          padding: 14px 0;
          width: 47%;
        }
      }
    }
  }
}

.declined-opponent-modal {
  max-width: 607px;

  .modal-content {
    background: #ffffff;
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding: 22px;

    .declined-wrapper {
      .close-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          cursor: pointer;
        }
      }

      h2 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #eb5569;
        margin: 15px 0;
      }

      p {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #a5a5a5;
        margin: 0px 10px 60px 10px;

        span {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          margin: 0;
        }

        span:nth-child(1) {
          color: #322e2f;
        }

        span:nth-child(2) {
          color: #ffb000;
        }
      }

      .footer-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background: #1a2b51;
          border-radius: 10px;
          padding: 13px 36px;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          border: none;
          margin-bottom: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .online-friends-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .online-card-wrapper {
      background: #ffffff;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
      border-radius: 25px;
      // min-width: 560px;
      max-width: 560px;
      padding: 24px 50px 21px 50px;
      height: fit-content;
      .online-card-title-wrapper {
        h3 {
          font-size: 19px;
          line-height: 21px;
        }
      }
      .online-user {
        h4 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 10px;
          color: #a5a5a5;
        }
      }
    }
  }

  .challenge-modal {
    .modal-content {
      background: #ffffff;
      box-shadow: none;
      border-radius: 18px;
      padding: 22px;

      .challenge-modal-wrapper {
        .blockchain-wrapper {
          .img-fluid {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }

          h1 {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #5e00f6;
          }
        }

        .challenge-img-wrapper {
          margin-bottom: 8px;
          margin: 0px 25px 16px 25px;
          .logo {
            width: 60%;
          }

          .center-line {
            border: 2px solid #cfcb00;
            height: 2px;
          }
        }

        .title-wrapper {
          margin: 0px 30px 30px 30px;

          .user {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #322e2f;
            margin-right: 6px;
          }

          .img-fluid {
            height: 12px;
            width: 19px;
          }
        }

        .modal-footer {
          margin: 0px;
          margin-bottom: 50px;

          button {
            padding: 11px 18px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .online-friends-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;

    .online-card-wrapper {
      background: #ffffff;
      box-shadow: none;
      border-radius: 25px;
      // min-width: 480px;
      max-width: 480px;
      padding: 24px 12px 21px 12px;
      height: fit-content;
    }
  }
}

@media screen and (max-width: 500px) {
  .online-friends-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;

    .online-card-wrapper {
      background: #ffffff;
      box-shadow: none;
      border-radius: 25px;
      // min-width: 450px;
      max-width: 450px;
      padding: 24px 24px 21px 24px;
      height: fit-content;
    }
  }

  .looking-opponent-modal {
    .modal-content {
      .waiting-user-wrapper {
        h1 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #322e2f;
          margin-bottom: 0px;
        }

        .user-data {
          .img-fluid {
            width: 95px;
            height: 95px;
          }
        }

        .text-center {
          .cancel-btn {
            padding: 11px 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .online-friends-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 18px;

    .online-card-wrapper {
      background: #ffffff;
      box-shadow: none;
      border-radius: 25px;
      // min-width: 380px;
      max-width: 380px;
      padding: 0px 6px 21px 6px;
      height: fit-content;

      .online-card-title-wrapper {
        img {
          display: block;
        }
      }

      .input-wrapper {
        background: rgba(227, 227, 227, 0.5);
        border-radius: 23.5px;

        input {
          // padding: 10px 25px 11px 50px;
          width: 100%;
        }

        ::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #a5a5a5;
        }
      }

      .online-user {
        h4 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 10px;
          color: #a5a5a5;
        }

        .row {
          .challenge-wrapper {
            .userName-wrapper {
              .avatar {
                height: 40px;
                width: 40px;
              }

              p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #322e2f;
              }
            }

            .challenge-btn {
              padding: 7px 16px;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #5cd149;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .waiting-user-modal {
    .modal-content {
      .waiting-user-wrapper {
        .waiting-title {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: #322e2f;
          width: 262px;
          margin: auto;
          padding: 0px;
          margin-bottom: 30px;
        }

        .starting-title {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #a5a5a5;
          margin-bottom: 13px;
        }

        .clock-wrapper {
          margin-top: 30px;
          height: 120px;
          width: 120px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .looking-opponent-modal {
    .modal-content {
      .waiting-user-wrapper {
        h1 {
          font-family: "Rubik";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #322e2f;
          margin-bottom: 8px;
        }
      }
    }
  }

  .challenge-modal {
    .modal-content {
      .challenge-modal-wrapper {
        .challenge-img-wrapper {
          margin-bottom: 8px;
          margin: 0px 15px 16px 15px;

          .center-logo {
            max-width: 76px;
            max-height: 27px;
          }
        }

        .title-wrapper {
          margin: 0px 15px 50px 15px;
        }
      }
    }
  }

  .declined-opponent-modal {
    .modal-content {
      .declined-wrapper {
        p {
          margin: 0px 10px 60px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .online-friends-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .online-card-wrapper {
      background: #ffffff;
      box-shadow: none;
      border-radius: 25px;
      padding: 0px 15px 21px 20px;
      height: fit-content;
      width: 100%;

      .online-user {
        .row {
          .challenge-wrapper {
            .userName-wrapper {
              .avatar {
                height: 35px !important;
                width: 35px !important;
              }

              .img-fluid {
                height: 25px;
                width: 25px;
              }
            }

            .challenge-btn {
              padding: 3px 8px;
            }
          }
        }
      }
    }
  }
}
